import React from "react";
import Layout from "../components/Layout/Layout";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import "./archives.scss";
import { toKebabCase } from "../utils/common";
import { TagsBlogSVG } from "../static/svgs";
import HeroSection from "../components/HeroSection/HeroSection";
import InternalLinkRight from "../components/Links/InternalLinkRight";

const TagsPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
  location,
}) => {
  {
    group.sort((a, b) =>
      a.totalCount > b.totalCount ? -1 : b.totalCount > a.totalCount ? 1 : 0
    );
  }
  const renderHeroSection = () => (
    <HeroSection title={"Blog Archives"} svg={<TagsBlogSVG />} />
  );

  return (
    <Layout location={location} renderOutsideContainer={renderHeroSection}>
      <SEO
        title={"Blog Archives"}
        description={
          "Blog Archives - Raj Rajhans. View past blogs by category. This blog reflects Raj's ideas, projects and learnings."
        }
        url={"/archives"}
      />

      <div className={"content-container"}>
        <div className={"TagsContentTitle"}>Blogs by Categories -</div>
        <ul className={"allTagsList"}>
          {group.map((tag) => (
            <InternalLinkRight
              link={`/tags/${toKebabCase(tag.fieldValue)}/`}
              title={`Check out all posts tagged "${tag.fieldValue}"`}
            >
              <li className={"achTagCntnr"} key={tag.fieldValue}>
                <div className="achTagName">
                  <span className={"brand-color"}>#</span>
                  {tag.fieldValue}
                </div>

                <div className="achTagCount">{tag.totalCount} Posts</div>
              </li>
            </InternalLinkRight>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
